import IconVeryGood from '@/assets/images/svg/very-good.svg'
import IconGood from '@/assets/images/svg/good.svg'
import IconMedium from '@/assets/images/svg/medium.svg'
import IconBad from '@/assets/images/svg/bad.svg'
import IconVeryBad from '@/assets/images/svg/very-bad.svg'

export const indicatorRatioRetur = [
  {
    id: 1,
    name: 'Sangat Bagus',
    img: IconVeryGood,
  },
  {
    id: 2,
    name: 'Bagus',
    img: IconGood,
  },
  {
    id: 3,
    name: 'Sedang',
    img: IconMedium,
  },
  {
    id: 4,
    name: 'Buruk',
    img: IconBad,
  },
  {
    id: 5,
    name: 'Sangat Buruk',
    img: IconVeryBad,
  },
]

export const fieldRasioRetur = [
  {
    key: 'city_name',
    label: 'Kota / Kab',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'jne',
    label: 'JNE',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'ideexpress',
    label: 'IDExpress',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'sicepat',
    label: 'SiCepat',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'sap',
    label: 'SAP',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'ninja',
    label: 'NINJA',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'j&t',
    label: 'J&T',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'average',
    label: 'Rata-rata',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'status',
    label: 'Status',
    class: 'p-1 align-middle text-center',
    thStyle: {
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const fieldSuccessHandling = [
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'bg-white align-middle p-0',
  },
  {
    key: 'retur_rate',
    label: 'Retur Rate',
    class: 'bg-white align-middle p-0 text-center',
  },
  {
    key: 'status',
    label: 'Status',
    class: 'bg-white align-middle p-0 text-center',
  },
  {
    key: 'success_rate',
    label: 'Success Rate',
    class: 'bg-white text-center',
  },
]

export const fieldFastHandling = [
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'bg-white p-1 align-middle text-center',
  },
  {
    key: 'service',
    label: 'Service',
    class: 'bg-white p-1 align-middle text-center',
  },
  {
    key: 'cod',
    label: 'COD',
    class: 'bg-white p-1 align-middle text-center',
  },
  {
    key: 'non_cod',
    label: 'Non-COD',
    class: 'bg-white p-1 align-middle text-center',
  },
  {
    key: 'average',
    label: 'Rata-rata',
    class: 'bg-white p-1 align-middle text-center',
  },
]
