import { render, staticRenderFns } from "./ReturArea.vue?vue&type=template&id=6bab2f00&scoped=true&"
import script from "./ReturArea.vue?vue&type=script&lang=js&"
export * from "./ReturArea.vue?vue&type=script&lang=js&"
import style0 from "../components/Ekspedisi.scss?vue&type=style&index=0&id=6bab2f00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bab2f00",
  null
  
)

export default component.exports